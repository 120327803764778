*{
  box-sizing: border-box;
  overflow-wrap: break-word;
}
@font-face {
  font-family: 'Brown';
  src: local('Brown'), url(./fonts/lineto-brown-regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Brown';
  font-weight: 900;
  src: local('Brown'), url(./fonts/lineto-brown-bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Brown';
  font-weight: 200;
  src: local('Brown'), url(./fonts/lineto-brown-light.woff2) format('woff2');
}

body {
  margin: 0;
  font-family: "Brown","Roboto","Oxygen","Segoe UI", -apple-system, BlinkMacSystemFont, 
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  color: #0061aa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input { 
  font-size: 16px !important;
}

textarea { 
  font-size: 16px !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #42464e;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(190, 198, 220, 0.7);
  border-radius: 4px;
}
